import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class EditPassword extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      modal: false,
	      password:'',
	      conferma_password:'',
	    };
	    this.UserApi = new UserApi();
	}

	open = () => {
		this.setState({
			modal: true
		})
	}

	toggle = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }


	  handleChange = (e) => {

		    const { name, value } = e.target;
		    console.log(name);
		    console.log(value);
		    this.setState({ 
		        [name]: value,
		      }
		    );
		  }


	 handleSubmit = () => {
    this.setState({ submitted: true });
     const { password, conferma_password } = this.state;

     
      if (!(password) || password != conferma_password) {
          return;
      }
      this.setState({ loading: true, error: false });
      this.UserApi.editPassword(password,() => {
      	this.setState({ loading: false, modal: false });
      });
  }

	render() {
		 const { password, conferma_password } = this.state;
		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
				 <ModalHeader toggle={this.toggle}>Modifica dati di accesso</ModalHeader>
				 <ModalBody>
					<Form>
			          <FormGroup>
			            <Label for="nome_profilo">Nuova password</Label>
			            <Input type="password" value={password} onChange={this.handleChange}  name="password" id="password" placeholder="Inserisci il nome del profilo" />
			          </FormGroup>

			          <FormGroup>
			            <Label for="nome_profilo">Conferma nuova password</Label>
			            <Input type="password" value={conferma_password} onChange={this.handleChange}  name="conferma_password" id="conferma_password" placeholder="Inserisci il nome del profilo" />
			          </FormGroup>

			        </Form>
				 </ModalBody>
				 <ModalFooter>
				  <Button color="primary" onClick={this.handleSubmit}>Ok</Button>{' '}
	            	<Button color="secondary" onClick={this.toggle}>Annulla</Button>
	            </ModalFooter>
			</Modal>
		)
	}

}


export default EditPassword;