import React from 'react';
import Config from '../../Config/Config';
import '../../App.css';
import UserApi from '../../Helper/Api/UserApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatUsers from '../PageComponents/ChatUsers';
import InfiniteScroll from 'react-infinite-scroller';
import Chat from '../PageComponents/Chat';
import {ReactComponent as Chiave}  from'../../Images/Chiave.svg';
import {ReactComponent as Logout}  from'../../Images/Logout.svg';
import {ReactComponent as Chatroom}  from'../../Images/Chatroom.svg';
import EditPassword from '../PageComponents/EditPassword';
import LogoutPopup from '../PageComponents/LogoutPopup';
import RicercaAvanzata from '../PageComponents/RicercaAvanzata';
import DettagliProfilo from '../PageComponents/DettagliProfilo';
import UserImage from '../PageComponents/UserImage';
import {ReactComponent as Logo}  from'../../Images/Logo.svg';
import { Button,Navbar,NavbarBrand,Card,Col,Row,Input,Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faImages, faImage, faUpload, faSignOutAlt, faCommentDots, faUserCircle, faUsers, faCheck, faSearchPlus, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'
import Storage from '../../Helper/Storage';

import Websocket from 'react-websocket';


class HomePage extends React.Component {

  constructor(props) {
    super(props);

    this.Storage = new Storage();
    this.state = {
      chatUserList: [],
      page: "listaChat",
      DropDownUserOpen: false,
      DropDownUserChat: false,
      listaChat:[],
      tag:[],
      selectedTag: [],
      testoRicerca: "",
      sesso: "",
      stato: "",
      profiloToView:null,
      has_more: false,
      search_result: false

    }
    this.UserApi = new UserApi();
    this.getProfileList();
    this.getChatList();
    this.getListaTag();
    this.classe = JSON.parse(this.Storage.getItem("chatData")).chat_type;
  


  }


  getListaTag = () => {
    this.UserApi.getTagsList(JSON.parse(this.Storage.getItem("chatData")).chat_type, (success, tag)=>{
      this.setState({tag})
    })
  }

  toggleDropDownChat =() => {
    this.setState(prevState => ({
      DropDownUserChat: !prevState.DropDownUserChat
    }));
  }

  toggleDropDownUser = () => {
    this.setState(prevState => ({
      DropDownUserOpen: !prevState.DropDownUserOpen
    }));
  }

  getChatList = () => {
    this.props.onChangeLoader(true);
    this.UserApi.getChatList((success,data) => {
      this.props.onChangeLoader(false);
     this.setState({
            listaChat: data.chatList,
        });
    });
  }

  getProfileList = () => {
    this.props.onChangeLoader(true);
    this.UserApi.getProfileList((success,profili) => {
      this.props.onChangeLoader(false);
     this.setState({
            profili,
        });
    });
  }

  handleData = (data) => {
    data = JSON.parse(data);
    if(data.messageType == "connected")
    {
      this.ws.sendMessage(JSON.stringify({messageType:"getChatUsers", userPerPage: Config.userPerPage, page: 0}));
    }
    if(data.messageType == "usersList")
    {
      let { users } = data
      const { newMessages, hasMore, page } = data;
      if(page != 0){
        users = this.state.chatUserList.concat(users);
      }
      this.setNewMessages(users, newMessages, hasMore, false);
    }
    if(data.messageType == "searchResult")
    {
      const { users, newMessages } = data;
      this.setNewMessages(users, newMessages ,false ,true);
    }
    if(data.messageType == "newUser")
    {
      const user = data.user_data;
      
      let { chatUserList } = this.state;
      const index = chatUserList.findIndex(cu => cu.fk_id_user === user.fk_id_user);
      if(index >= 0)
      {
        if(chatUserList[index].da_leggere != undefined){
          user.da_leggere=chatUserList[index].da_leggere
        }
        chatUserList[index] = user;
      }else{
        chatUserList.push(user);
      }
      if (this.state.user != undefined && user.fk_id_user == this.state.user.fk_id_user)
      {
        this.setState({
          user,
        })
      }
      const { newMessages } = data;
      this.setNewMessages(chatUserList, newMessages, this.state.has_more, false);

    }

    if(data.messageType == "messageSended")
    {
      this.chatPage.getNewMessages();
    }

    if(data.messageType == "newMessagesList")
    {
      const { user, page, chatUserList } = this.state;
      const { newMessages } = data;
   

      if(page == "Chat"){
        for(let i in newMessages)
        {
          if(newMessages[i].fk_id_user == user.fk_id_user)
          {
            this.chatPage.getNewMessages();
          }
        }
      }
      this.setNewMessages(chatUserList, newMessages, this.state.has_more, this.state.search_result);
      
    }
  }

  setNewMessages = (chatUserList, newMessages, has_more, search_result) => {

    for(let i in newMessages)
    {
      let index = chatUserList.findIndex(cu => cu.fk_id_user === newMessages[i].fk_id_user)
      console.log(index);
      if(index >= 0)
      {
        chatUserList[index].da_leggere = newMessages[i].da_leggere;
        let first = chatUserList[index];
        chatUserList.splice(index,1);
        chatUserList.unshift(first);
      }
    }

    if(this.props.match.params.id_user !== undefined && this.state.page == "listaChat")
    {
      let id_user = this.props.match.params.id_user;
      console.log(id_user);
      console.log(chatUserList);
      //const { chatUserList } = this.state;
      const index = chatUserList.findIndex(cu => cu.fk_id_user === parseInt(id_user));
      console.log(index);
      if(index >= 0)
      {
        chatUserList[index].da_leggere = 0;
         this.setState({
          user: chatUserList[index],
          page: "Chat",
          chatUserList,
          has_more,
          search_result,
          mustLoadChat: false
        });
      }
    }else if(this.props.match.params.id_user === undefined && this.state.page == "Chat"){
      this.setState({
        user: null,
        page: "listaChat",
      });
    }
    this.setState({
        chatUserList,
        has_more,
        search_result,
      })
  }


  connected = (ws) => {
    this.ws.sendMessage(JSON.stringify({messageType:"socketLogin", token:this.Storage.getItem('token')}));
  }

  goRicercaAvanzata = () => {
    this.ricercaAvanzata.open();
  }

  pulisciRicerca = () => {
    this.ws.sendMessage(JSON.stringify({messageType:"getChatUsers", userPerPage: Config.userPerPage, page:0}));
    this.setState({
      selectedTag : [],
      testoRicerca : '',
      sesso : '',
      stato : '',
    });
  }

  effettuaRicercaAvanzata = (selectedTag, testoRicerca, sesso, stato) => {
    this.setState({
      selectedTag,
      testoRicerca,
      sesso,
      stato,
    });
    this.ws.sendMessage(JSON.stringify({messageType:"advacedSearch", selectedTag, testoRicerca, sesso, stato }));
    this.ricercaAvanzata.close();
  }

  changeRicerca = (e) => {

      const { value } = e.target;

      this.setState({ 
          ricerca: value,
        }
      );
      if(value != "")
      {
        this.ws.sendMessage(JSON.stringify({messageType:"search", testo: value}));
      }else{
        this.ws.sendMessage(JSON.stringify({messageType:"getChatUsers", userPerPage: Config.userPerPage, page:0}));
      }
      
    }

  showProfilo = (id_profilo) => {
    this.props.onChangeLoader(true);
    this.UserApi.profileData(id_profilo,(success,profiloToView) => {
      this.props.onChangeLoader(false);
      this.setState({
        profiloToView
      });
      this.dettagliProfilo.open();
    });
  }

  listaUtenti = () => {
    const { chatUserList, selectedTag, testoRicerca, sesso, stato, ricerca } = this.state;
    console.log(chatUserList);
      const arrUtentui = [];
      for(let i in chatUserList){
        arrUtentui.push(<ChatUsers data={chatUserList[i]} onSelect={this.goChat} onShowProfilo={this.showProfilo}  />);
      }
      let ricercaAvanzata = <a  href="javascript:void(0)" onClick={this.goRicercaAvanzata} className="btn bg-chattamee text-white" title="Ricerca avanzata"><FontAwesomeIcon icon={faSearchPlus} size="1x" /><div style={{fontSize: "10px", marginTop:"-7px"}}>Ricerca avanzata</div></a>;
      if(selectedTag.length > 0 || testoRicerca != "" || sesso != "" || stato != ""){
        ricercaAvanzata = <a href="javascript:void(0)" onClick={this.pulisciRicerca} className="btn bg-chattamee text-white" title="Ricerca avanzata"><FontAwesomeIcon icon={faTrash} size="1x" /><div style={{fontSize: "10px", marginTop:"-7px"}}>Pulisci dati di ricerca</div></a>
      }

      let lista_utenti = arrUtentui;
      if(!this.state.search_result)
      {
        lista_utenti = (<InfiniteScroll
    pageStart={0}
    loadMore={this.loadNew}
    hasMore={this.state.has_more }
    loader={<div className="loader" key={0}>Loading ...</div>}
    threshold={10}
>
              {arrUtentui}
              </InfiniteScroll>
            )
      }
      return (
        <div className="inbox_people" style={{marginTop:'90px'}}>
          <div className="headind_srch">
            
            <div className="srch_bar">
              <div className="stylish-input-group" style={{position:"relative","zIndex":1}}>
                <input className="search-bar" name="ricerca" value={ricerca}  onChange={this.changeRicerca} placeholder="Cerca" />
                <div class='btn-group' style={{position:"absolute",top:"-10px",right:"1px", paddingLeft:"5px", backgroundColor:"white"}}>
                  {ricercaAvanzata}
                </div>
              </div>
            </div>
            
          </div>
            <div className="inbox_chat scroll">
              {lista_utenti}
              </div>
         </div>
      );
  }

  goLista = () => {
     /*this.setState({
        user: null,
        page: "listaChat",
      });*/
      const { from } =  { from: { pathname: `/` } };
    this.props.history.push(from);
  }

  goChat = (id_user) => {
    const { from } =  { from: { pathname: `/chat/${id_user}` } };
    this.props.history.push(from);



    
  }


  chat = () => {
    const { user } = this.state;
    return <Chat user={user} socket={this.ws} ref={ Chat => { this.chatPage = Chat; }} />
  }

  nuovoCodice = () => {
    this.Storage.removeItem('chatData');
    this.Storage.removeItem('profile_selected');
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    this.props.history.push(from);
  }
  selectChatCode = (codice_chat) => {
    this.setState({ submitted: true });
     

      if (!(codice_chat)) {
          return;
      }
      this.setState({ loading: true, error: false });
      this.props.onChangeLoader(true);
      this.UserApi.setChatCode(codice_chat, (success,data) => {
        this.props.onChangeLoader(false);
         if(!success)
        {
          this.setState({
            error: data,
            loading: false,
          });
        }else{
          let chat = {
            codiceChat: codice_chat,
            name: data.chat,
            chat_type: data.chat_type

          }
          this.Storage.setItem('chatData', JSON.stringify(chat));
          this.Storage.removeItem('profile_selected');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        } 
   
        }
      );
  }
  renderListaChat = () => {
    const { listaChat } = this.state;
    const arrChat = [];
    for(var i in listaChat){
      let data = listaChat[i]
      let sel = null;
        if(data.attiva == 1)
        {
          sel = <FontAwesomeIcon icon={faCheck} className='pull-right text-success' style={{    marginTop:'4px'}}  />
        }
      arrChat.push(<DropdownItem onClick={(e) => this.selectChatCode(data.codice)} >
          {data.ragione_sociale}-{data.chatroom_nome}[{data.codice}]{sel}
    

          </DropdownItem>);
    }
    return arrChat;
  }

  renderListaProfili = () => {
    const arrProfili = [];
    let profile_selected = this.Storage.getItem("profile_selected");
    const { profili } = this.state;
    if (profili != null) {
      for(var i in profili){
        let data = profili[i];
        let {sesso} = data;
        if(sesso == null){
          sesso = "";
        }
        let img = `${Config.apiPath}/image/avatar${sesso}.png`;
        if(data.immagine_profilo != null)
        {
          img = `${Config.apiPath}/image/${data.immagine_profilo}`;
        }
        let sel = null;
        if(profile_selected == data.id_profilo)
        {
          sel = <FontAwesomeIcon icon={faPencilAlt} className='pull-right text-success' style={{    marginTop:'4px'}}  />
        }
        arrProfili.push(<DropdownItem onClick={(e) => this.modificaProfilo(data.id_profilo)} >
          <img src={img}   style={{borderRadius: '50%', height: '25px'}} /> {data.nickname}{sel}
    

          </DropdownItem>);
      }
    } 
    return arrProfili;

     
  }
  loadNew = (page) => {
    console.log("LOADNEW");
    console.log(page);
   this.ws.sendMessage(JSON.stringify({messageType:"getChatUsers", userPerPage: Config.userPerPage, page: page}));
  }
  modificaProfilo = (id_profilo) => {
    const { from } =  { from: { pathname: `/SelectProfile/editProfile/${id_profilo}` } };
        this.props.history.push(from);
  }
  selezionaProfilo = (id_profilo) => {
    this.props.onChangeLoader(true);
    this.UserApi.selectProfile(id_profilo,(success,result) => {
      this.props.onChangeLoader(false);
      if(success && result.result)
      {
        this.Storage.setItem('profile_selected', id_profilo);
         this.ws.sendMessage(JSON.stringify({messageType:"socketLogin", token:this.Storage.getItem('token')}));
      }

    });
  }


  nuovoProfilo = () => {
    const { from } =  { from: { pathname: "/SelectProfile/newProfile" } };
        this.props.history.push(from);
  }

  listaProfili = () => {
    const { from } =  { from: { pathname: "/SelectProfile" } };
        this.props.history.push(from);
  }

  logoutPopup = () =>{
    this.logpp.open();
  }

  render(){
    const { onLogout } = this.props;
    const { page, user, tag, selectedTag, testoRicerca, sesso, stato, profiloToView  } = this.state;


    return (
      <div className={this.classe}>
        <Websocket url={Config.wsEndpoint}
              onMessage={this.handleData}
              onOpen={this.connected}
              ref={ Websocket => { this.ws = Websocket; }}
        />
        <EditPassword ref={ EditPassword => { this.editpwd = EditPassword; }} />
        <LogoutPopup onLogout={onLogout} onChangeChat={this.nuovoCodice} ref={ LogoutPopup => { this.logpp = LogoutPopup; }} />
        <RicercaAvanzata tag={tag} selectedTag={selectedTag} testoRicerca={testoRicerca} sesso={sesso} stato={stato} onSearch={this.effettuaRicercaAvanzata} ref={ RicercaAvanzata => { this.ricercaAvanzata = RicercaAvanzata; }} />
        <DettagliProfilo profilo={profiloToView} ref={ DettagliProfilo => { this.dettagliProfilo = DettagliProfilo; }} />
        <header style={{position: 'fixed', width: '100%', 'zIndex':999}}>
          <Navbar color="light" light expand="md" style={{paddingLeft: '3px', paddingRight: '3px'}}>
             <div className="col-5 p-0">
              {
                page == 'listaChat' 
                ? <Logo style={{width: '100%', maxHeight:'70px'}} />
                : <span><FontAwesomeIcon onClick={this.goLista} style={{float: "left", marginRight: "12px", cursor: "pointer"}} icon={faChevronLeft} size='2x' className="text-chattamee" /> <UserImage user={user} /></span>
              }
              </div>
              <div className="col-7 text-right p-0">
              <a href="javascript:void(0)" onClick={this.logoutPopup} className='text-chattamee mx-1' style={{display: "inline-block"}}> 
                <Logout style={{height: '32px', width: '32px'}} />
                <br />
                <span style={{fontSize:"11px"}}>Logout</span>
              </a>
              <Dropdown style={{display: 'inline-block'}} isOpen={this.state.DropDownUserChat} toggle={this.toggleDropDownChat}>
              <DropdownToggle style={{backgroundColor: 'transparent',border:0, marginTop: '-33px'}}>
                <a href="javascript:void(0)" className='text-chattamee'  style={{display: "inline-block"}}>
                <Chiave style={{height: '32px', width: '32px'}} />
                <br />
                <span style={{fontSize:"11px"}}>Profilo</span>
                </a>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Seleziona un profilo</DropdownItem>
                {this.renderListaProfili()}
                <DropdownItem onClick={this.nuovoProfilo}>Nuovo profilo</DropdownItem>
                <DropdownItem onClick={this.listaProfili}>Lista profili</DropdownItem>
                 <DropdownItem divider />
                <DropdownItem onClick={(e) => this.editpwd.open()}>Modifica dati di accesso</DropdownItem>
              </DropdownMenu>
              </Dropdown>

              <Dropdown style={{display: 'inline-block'}} isOpen={this.state.DropDownUserOpen} toggle={this.toggleDropDownUser}>
              <DropdownToggle style={{backgroundColor: 'transparent',border:0, marginTop: '-33px'}}>
              <a href="javascript:void(0)" className='text-chattamee' style={{display: "inline-block"}}>
                <Chatroom style={{height: '32px', width: '32px'}} />
                <br />
                <span style={{fontSize:"11px"}}>ChatRoom</span>
              </a>
              </DropdownToggle>
              <DropdownMenu right='false' style={{width: '300px', right:'9px'}}>
              <DropdownItem header>Accedi alla chat</DropdownItem>
              {this.renderListaChat()}
              <DropdownItem onClick={this.nuovoCodice}>Inserisci un nuovo codice</DropdownItem>
              </DropdownMenu>
              </Dropdown>
              </div>
          </Navbar>
          {page == 'listaChat' || true ? null:<Navbar className="navbar-user"><UserImage user={user} /></Navbar>}
        </header>
        {page =='listaChat' ? this.listaUtenti() : this.chat()}
      </div>
      );
    }
  }

  export default HomePage; 
