import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import TagList from './TagList';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class ChatDisclaimer extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      modal: false,
	      accettato: 0,
	    };
	}
	open = (disclaimer) => {
		this.setState({
			modal: true
			,disclaimer
		})
	}

	onPressOk = () => {
		const { accettato } = this.state;
		const { onAccepted } = this.props;
		if(accettato == 0)
		{
			return;
		}
		this.close();
		onAccepted();
	}
	close = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	toggle = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	  handleCheckbox = (e) => {
   const { name, checked } = e.target;
   const value = checked?1:0;
    this.setState({ 
        [name]: value,
      }
    );
  
}

	  	render() {
		const { disclaimer, accettato } = this.state;
	
		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
				 <ModalHeader toggle={this.toggle}>Disclaimer</ModalHeader>
				 <ModalBody>
				 <Input type="textarea" name="text" id="exampleText" style={{height:"100px",fontSize:"12px"}} value={disclaimer} readonly="readonly" />
				 	
				 	<FormGroup check>
          <Label check>
            <Input type="checkbox" name='accettato' value="1" defaultChecked={accettato==1} onChange={this.handleCheckbox} />
            Ho letto ed accetto il disclaimer
          </Label>
        </FormGroup>
                  
				 </ModalBody>
				 <ModalFooter>
				  
	            	<Button color="secondary" onClick={this.onPressOk}>Ok</Button>
	            </ModalFooter>
			</Modal>
		)
	}

}

export default ChatDisclaimer;