import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import SingleMessage from './SingleMessage';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'
class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messaggio: "",
      messaggi: [],
      profilo: null,
      max_id_chat_message: 0
    }
    this.UserApi = new UserApi();
    this.getMessages();

  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }


  getMessages = () => {
    const { user } = this.props;
    const { fk_id_user } = user;
    let { max_id_chat_message } = this.state;
    
    this.UserApi.getMessages(fk_id_user, true,0, (success, ret) => {
      if (success) {
        const { messaggi, profilo } = ret;
       for(let i in messaggi)
        {
          if(messaggi[i].id_chat_message > max_id_chat_message)
          {
            max_id_chat_message = messaggi[i].id_chat_message;
          }
        }
        this.setState({ messaggi, profilo, max_id_chat_message });
      }
    })
  }

  getNewMessages = () => {
    let { messaggi, max_id_chat_message } = this.state;
    const { user } = this.props;
    const { fk_id_user } = user;
    this.UserApi.getMessages(fk_id_user, false, max_id_chat_message, (success, ret) => {
      const nuoviMessaggi = ret.messaggi;
      for(let i in nuoviMessaggi)
        {
          if(nuoviMessaggi[i].id_chat_message > max_id_chat_message)
          {
            max_id_chat_message = nuoviMessaggi[i].id_chat_message;
          }
        }
      messaggi = messaggi.concat(nuoviMessaggi);
       this.setState({ messaggi, max_id_chat_message });
    });
  }

  sendMessage = () => {
    const { socket, user } = this.props;
    
    const { messaggio } = this.state;
    if(messaggio == ""){
      return;
    }
    let message = {
      messaggio,
      to: user.fk_id_user,
      messageType: "sendMessage",
    }
    socket.sendMessage(JSON.stringify(message));
    this.setState({messaggio:""});
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ 
      [name]: value,
    }
    );
  }


  getMessagesHtml = () => {
    const { messaggi, profilo } = this.state;

    const { user } = this.props;
    const _messaggi = [];

    for(let i in messaggi)
    {

      _messaggi.push(<SingleMessage destinatario={user} mittente={profilo} message={messaggi[i]} />)
    }
    return _messaggi;
  }

  render() {
    const { messaggio } =  this.state;
    return(
        <div className="messaging">
          <div className="inbox_msg">
            <div className="mesgs">
              <div className="msg_history">
                {this.getMessagesHtml()}
                <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }} />
              </div>
              <div className="type_msg">
                <div className="input_msg_write">
                  <input value={messaggio} onChange={this.handleChange} type="text" className="write_msg" name='messaggio' placeholder="Scrivi un messaggio" />
                  <button className="msg_send_btn" type="button" onClick={this.sendMessage}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default Chat;
