import React from 'react';


import Storage from '../Helper/Storage';
import UserApi from '../Helper/Api/UserApi';
const queryString = require('query-string');

class TokenLogin extends React.Component {
  constructor(props) {
  	super(props);;
    this.UserApi = new UserApi();
    this.Storage = new Storage();
    const parsed = queryString.parse(props.location.search);
    console.log(parsed);
    console.log(props.location.search);
    this.UserApi.tokenLogin(parsed.token,(success, data)=>{
      
      this.Storage.setItem('token', data.access_token);
      this.Storage.setItem('email', data.email);
      //console.log(props.location.store);
      if(parsed.store !== undefined)
      {
        this.Storage.setItem('store', parsed.store);
      }
      const { from } = this.props.location.state || { from: { pathname: "/" } };
      this.props.history.push(from);

      
    });

    console.log(parsed);
  }

  

  render() {
    return null;
  }
}
export default TokenLogin;