import React from 'react';
import Api from '../Api.js';
export class UserApi extends Api {
	login = (username,password, callback) => {
		let param = {
			username,
			password,
		};
		this.oauthLogin(param, callback)
	}

	setChatCode = (chat_code, callback) => {
		this.post('chatCode', {chat_code, store_id: this.Storage.getItem("store")}, callback);
	}

	uploadImage = (formData, callback) =>{
		this.post('image',formData, callback, false)
	}

	aggiornaProfilo = (data, id_profilo, callback) => {
		
		this.patch(`profile/${id_profilo}`, data, callback);
	}

	salvaProfilo = (data, callback) => {
		this.post('profile', data, callback);
	}

	getProfileList = (callback) => {
		this.get('profile', {}, callback);
	}

	profileData = (id_profilo, callback) => {
		this.get(`profileData/${id_profilo}`, {}, callback);
	}

	getProfile = (id_profilo, callback) => {
		this.get(`profile/${id_profilo}`, {}, callback);
	}

	getUser = (callback) => {
		this.get(`user`, {}, callback);
	}

	selectProfile = (id_profilo, callback) => {
		this.link(`profile/${id_profilo}`, {}, callback);
	}

	creaAccount = (email, callback) => {
		this.post(`user`, {email}, callback);
	}

	recuperaPassword = (email, callback) => {
		this.post(`user/recuperaPassword`, {email}, callback);
	}



	resetPassword = (password, hash , callback) => {
		this.post(`user/resetPassword`, {password, hash}, callback);
	}

	getChatList = (callback) => {
		this.get('chatList', {}, callback);
	}

	editPassword = (password, callback) => {
		this.post(`user/editPassword`, {password}, callback);
	}
	getTagsList = (chatType, callback) => {
		this.get(`tagList/${chatType}`, {}, callback);
	}

	acceptDisclaimer = (callback) => {
		this.get(`accceptDisclaimer`, {}, callback);
	}
	tokenLogin = (token, callback) =>{
		this.post(`user/tokenLogin`, {token}, callback);
	}
	getMessages = (id_user, get_profilo,max_id_chat_message, callback) => {
		let query = [];

		if (get_profilo) {
			query.push("get_profilo=1");
		}
		if (max_id_chat_message > 0){
			query.push(`min_id_chat_message=${max_id_chat_message}`);
		}
		let queryString = "";
		if(query.length > 0)
		{
			query = query.join("&");
			queryString = `?${query}`
		}
		this.get(`chat/messages/${id_user}${queryString}`, {}, callback);
		
	}

}
export default UserApi;