import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'
class UserImage extends React.Component {
	constructor(props) {
	    super(props);
	
	}


	render(){
		let { user, goBack } = this.props;
		let {sesso} = user;
		if(sesso == null){
			sesso = "";
		}
		let img = `${Config.apiPath}/image/avatar${sesso}.png`;
		if(user.immagine_profilo != null)
		{
			img = `${Config.apiPath}/image/${user.immagine_profilo}`;
		}
		let nickname = user.nickname;
		if(nickname.length > 8)
		{
			nickname = nickname.substr(0,8)+"...";
		}
		return(
			<div><img src={img} alt=""  style={{borderRadius: "50%", height: "35px"}} /> {nickname} </div>
		)
	}
}

export default UserImage;
