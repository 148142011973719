import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import {ReactComponent as Chiave}  from'../../Images/Chiave.svg';
import {ReactComponent as Logout}  from'../../Images/Logout.svg';
import {ReactComponent as Chatroom}  from'../../Images/Chatroom.svg';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import Profilo from '../PageComponents/Profilo';
import EditPassword from '../PageComponents/EditPassword';
import LogoutPopup from '../PageComponents/LogoutPopup';
import TagList from '../PageComponents/TagList';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ReactComponent as Logo}  from'../../Images/Logo.svg';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input,Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faImage, faUpload, faSignOutAlt, faCommentDots, faUserCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class SelectProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        codice_chat: '',
        submitted: false,
        loading: false,
        error: '',
        page:props.page,
        uploading: false,
        image: null,
        profili: null,
        DropDownUserOpen: false,
        DropDownUserChat: false,
        tag: [],
        selectedTag: [],
    };
    this.UserApi = new UserApi();
    this.Storage = new Storage();
    this.getProfileList(true);
    this.getChatList();
    this.getListaTag();
    
    this.classe = JSON.parse(this.Storage.getItem("chatData")).chat_type;
    if(props.page == "GO Modifica profilo")
    {
      this.editProfile(props.match.params.id_profilo) 
    }
  }

  getListaTag = () => {
    this.UserApi.getTagsList(JSON.parse(this.Storage.getItem("chatData")).chat_type, (success, tag)=>{
      this.setState({tag})
    })
  }

  toggleDropDownChat =() => {
    this.setState(prevState => ({
      DropDownUserChat: !prevState.DropDownUserChat
    }));
  }


  toggleDropDownUser = () => {
    this.setState(prevState => ({
      DropDownUserOpen: !prevState.DropDownUserOpen
    }));
  }

  getChatList = () => {
    this.props.onChangeLoader(true);
    this.UserApi.getChatList((success,data) => {
      this.props.onChangeLoader(false);
     this.setState({
            listaChat: data.chatList,
        });
    });
  }

  selezionaProfilo = (id_profilo) => {
    this.props.onChangeLoader(true);
    this.UserApi.selectProfile(id_profilo,(success,result) => {
      this.props.onChangeLoader(false);
      if(success && result.result)
      {
        this.Storage.setItem('profile_selected', id_profilo);
        const { from } =  { from: { pathname: "/" } };
        this.props.history.push(from);
      }

    });
  }

  editProfile = (id_profilo) => {
 
    this.props.onChangeLoader(true);
    this.UserApi.getProfile(id_profilo,(success,profilo) => {
      this.props.onChangeLoader(false);
        let tag = profilo.tags.blue;
        if(JSON.parse(this.Storage.getItem("chatData")).chat_type == "red")
        {
          tag = profilo.tags.red;
        }
        this.setState({
          nome_profilo:profilo.nome_profilo,
          email:profilo.email,
          telefono:profilo.telefono,
          eta:profilo.eta,
          sesso:profilo.sesso,
          status:profilo.stato,
          descrizione:profilo.descrizione,
          image:profilo.immagine_profilo,
          nickname: profilo.nickname,
          page: 'Nuovo profilo',
          title: 'Modifica profilo',
          id_profilo,
          selectedTag: tag,
          mostraEta: profilo.mostra_eta
        });
        
    });
  }

  getProfileList = (dontChangePage) => {
    console.log(dontChangePage)
    if(dontChangePage === undefined)
    {
      dontChangePage = false;
    }
    if(dontChangePage !== true && dontChangePage !== false)
    {
      dontChangePage = false;
    }
    this.props.onChangeLoader(true);
    this.UserApi.getProfileList((success,profili) => {
      this.props.onChangeLoader(false);
      this.setState({
            profili,
            page: dontChangePage?this.state.page:'lista_profili',
            loading: false,
        })
    });
  }
  handleChange = (e) => {

    const { name, value } = e.target;
    console.log(value);
    this.setState({ 
        [name]: value,
      }
    );
  }

  handleCheckbox = (e) => {
   const { name, checked } = e.target;
   const value = checked?1:0;
    this.setState({ 
        [name]: value,
      }
    );
  
  }

  handleChangeFoto = (e) => {
    const files = Array.from(e.target.files)
    this.setState({ uploading: true })
     const formData = new FormData();
     files.forEach((file, i) => {
      formData.append('image', file)
    });

     this.UserApi.uploadImage(formData,(success,data) => {
      this.setState({
        uploading: false,
        image: data.fileName
      })
     })
  }

  handleSubmit = () => {
    let listaTagSelezionati = this.tagManager.getListaTagSelezionati();
    this.setState({ submitted: true });
     const { codice_chat, returnUrl } = this.state;
      const { nome_profilo, nickname, email, telefono, eta, sesso, status, descrizione, image, id_profilo, mostraEta } = this.state;
      if (!(nome_profilo) || !(nickname) || !(email)) {
          return;
      }
      this.setState({ loading: true, error: false });
      let data = {
        nome_profilo,
        nickname,
        email,
        telefono,
        eta,
        sesso,
        status,
        descrizione, 
        mostra_eta: mostraEta,
        immagine_profilo: image,
        'listaTagSelezionati[]': listaTagSelezionati,
        chatType: JSON.parse(this.Storage.getItem("chatData")).chat_type,
      }

      if(id_profilo == null){
        this.props.onChangeLoader(true);
        this.UserApi.salvaProfilo(data, (success,data) => {
          this.props.onChangeLoader(false);
           if(!success)
          {
            this.setState({
              error: data,
              loading: false,
            });
          }else{
            this.selezionaProfilo(data.id_profilo)
            //this.getProfileList();
          } 
     
          }
        );
      }else{
        this.props.onChangeLoader(true);
        this.UserApi.aggiornaProfilo(data, id_profilo, (success,data) => {
          this.props.onChangeLoader(false);
           if(!success)
          {
            this.setState({
              error: data,
              loading: false,
            });
          }else{
            this.selezionaProfilo(id_profilo)
            //this.getProfileList();
          } 
     
          }
        );
      }
  }
 goNuovoProfilo = () => {
  this.setState({
          nome_profilo: null,
          email: this.Storage.getItem("email"),
          telefono: null,
          eta: null,
          sesso: null,
          status: null,
          descrizione: null,
          image: null,
          page: 'Nuovo profilo',
          title: 'Nuovo profilo',
          nickname: null,
          id_profilo: null,
          selectedTag: [],
          mostraEta: 0

        });

 }
  listaProfili = () => {
    const arrProfili = [];
    const { profili } = this.state;
    if (profili != null) {
      for(var i in profili){
        arrProfili.push(<Profilo data={profili[i]} onEdit={this.editProfile} onSelect={this.selezionaProfilo} />);
      }
    }
   const select = <div style={{fontFamily:'Roboto', fontWeight: 100, borderBottom:'1px solid #ddd'}}>Crea un nuovo profilo o accedi con uno esistente</div>;
    
    return (
      <Row className='mx-2'>
        <Col md='12' style={{textAlign:'center'}}>
          
       
        {select}
         </Col>
        <div className="inbox_chat scroll">
        {arrProfili}
        </div>
        <Button type='button' onClick={this.goNuovoProfilo} className='btn bg-chattamee text-white pull-right' style={{position:'absolute',bottom:"6px",width:'96%'}}>Nuovo profilo</Button>
      </Row>
      )
  }

  changeFoto = () =>{
    this.setState({image: null})
  }

  uploadButton = () => {
    if(this.state.uploading)
    {
      return (
        <div>
          <Label for="">Carica una foto del profilo</Label><br />
          <Spinner />
        </div>
        )
    }
    if(this.state.image != null)
    {
      let url = `${Config.apiPath}image/${this.state.image}`;
      return (
        <div>
          <Label for="">Carica una foto del profilo</Label><br />
          <img style={{width: '100px'}} src={url} />
          <br />
          <a onClick={this.changeFoto} class='text text-success'>Modifica</a>
        </div>
      )
    }

    return (<div className='button'>
          <Label for="">Carica una foto del profilo</Label><br />

          <label For='single' className="btn bg-chattamee text-white btn-block mt-4 mb-4" style={{cursor: 'pointer'}}>
          <FontAwesomeIcon icon={faUpload} color='white' size='12px' />  Carica immagine
          </label>
          <input type='file' id='single' style={{display: 'none'}} onChange={this.handleChangeFoto} />  
        </div>);
  }

  nuovoProfilo = () => {
     const { nome_profilo, nickname, email, telefono, eta, sesso, status, descrizione, loading, error, title, tag, selectedTag, mostraEta } = this.state;

    return (
      <div class="row mx-2">
                        <div class="col-md-4 mx-auto mt-5">
                        <Card>
                        <h2 class="text-center mt-4 mb-4">{title}</h2>
                        <Col>
       <Form>
        <FormGroup>
          <Label for="nome_profilo">Nome del profilo <sup>*</sup></Label>
          <Input type="text" value={nome_profilo} onChange={this.handleChange}  name="nome_profilo" id="nome_profilo" placeholder="Inserisci il nome del profilo" />
        </FormGroup>


        <FormGroup>
          {this.uploadButton()}
        </FormGroup>

        <FormGroup>
          <Label for="nickname">Nickname <sup>*</sup></Label>
          <Input type="text" value={nickname} onChange={this.handleChange}  name="nickname" id="nickname" placeholder="Inserisci il nickname" />
        </FormGroup>
        <FormGroup>
          <Label for="email">Indirizzo email <sup>*</sup></Label>
          <Input type="text" value={email} onChange={this.handleChange}  name="email" id="email" placeholder="Inserisci l'indirizzo email" />
        </FormGroup>
         <FormGroup>
          <Label for="telefono">Numero di telefono</Label>
          <Input type="text" value={telefono} onChange={this.handleChange}  name="telefono" id="telefono" placeholder="Inserisci il numero di telefono" />
        </FormGroup>
        <FormGroup>
          <Label for="eta">Età</Label>
          <Input type="text" value={eta} onChange={this.handleChange}  name="eta" id="eta" placeholder="Inserisci la tua età" />
        </FormGroup>
         <FormGroup check>
          <Label check>
            <Input type="checkbox" name='mostraEta' value="1" defaultChecked={mostraEta==1} onChange={this.handleCheckbox} />
            Mostra l'età sul profilo
          </Label>
        </FormGroup>
        <FormGroup>
          <Label for="sesso">Sesso</Label>
          <Input type="select" value={sesso} onChange={this.handleChange}  name="sesso" id="sesso" placeholder="Seleziona il tuo genere">
             <option>Inserisci il tuo sesso</option>
             <option value='M'>Uomo</option>
             <option value='F'>Donna</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="status">Status</Label>
          <Input type="text" value={status} onChange={this.handleChange}  name="status" id="status" placeholder="Inserisci il tuo status" />

        </FormGroup>
         <FormGroup>
          <Label for="descrizione">Raccontati</Label>
          
          <Input type="textarea" value={descrizione} onChange={this.handleChange}  name="descrizione" id="descrizione" placeholder="la tua Storia, il tuo Motto, il tuo Stile e la tua filosofia di Vita" />
        </FormGroup>
        <Label>Seleziona uno o più tag, serviranno per farti trovare</Label>
        <TagList tag={tag} selectedTag={selectedTag} ref={ TagList => { this.tagManager = TagList; }} />
        <Row>
        <Col md='6'>
         <Button type='button' onClick={this.handleSubmit} className="btn bg-chattamee text-white btn-block mt-4 mb-4">Salva profilo</Button>
           {loading &&
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            }
          </Col>
          <Col md='6'>
          <Button type='button' onClick={this.getProfileList} className="btn bg-chattamee text-white btn-block mt-4 mb-4">Torna indietro</Button>
          </Col>
          </Row>
          <br />
          {error &&
                <div className={'alert alert-danger'}>{error}</div>
            }
          <br />

       </Form>
       </Col>
       </Card>
      </div>
      </div>)
  }
  nuovoCodice = () => {
    this.Storage.removeItem('chatData');
    this.Storage.removeItem('profile_selected');
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    this.props.history.push(from);
  }
  selectChatCode = (codice_chat) => {
    this.setState({ submitted: true });
     

      if (!(codice_chat)) {
          return;
      }
      this.setState({ loading: true, error: false });
      this.props.onChangeLoader(true);
      this.UserApi.setChatCode(codice_chat, (success,data) => {
        this.props.onChangeLoader(false);
         if(!success)
        {
          this.setState({
            error: data,
            loading: false,
          });
        }else{
          let chat = {
            codiceChat: codice_chat,
            name: data.chat,
            chat_type: data.chat_type

          }
          this.Storage.setItem('chatData', JSON.stringify(chat));
          this.Storage.removeItem('profile_selected');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        } 
   
        }
      );
  }


  renderListaChat = () => {
    const { listaChat } = this.state;
    const arrChat = [];
    for(var i in listaChat){
      let data = listaChat[i]
      let sel = null;
        if(data.attiva == 1)
        {
          sel = <FontAwesomeIcon icon={faCheck} className='pull-right text-success' style={{    marginTop:'4px'}}  />
        }
      arrChat.push(<DropdownItem onClick={(e) => this.selectChatCode(data.codice)} >
          {data.ragione_sociale}-{data.chatroom_nome}[{data.codice}]{sel}
    

          </DropdownItem>);
    }
    return arrChat;
  }

  logoutPopup = () =>{
    this.logpp.open();
  }
  render(){
    const { codice_chat, loading, error, page } = this.state;
    const { onLogout } = this.props;
    
    return (
      <div className={this.classe}>
      <EditPassword ref={ EditPassword => { this.editpwd = EditPassword; }} />
      <LogoutPopup onLogout={onLogout} onChangeChat={this.nuovoCodice} ref={ LogoutPopup => { this.logpp = LogoutPopup; }} />
         <header>
          <Navbar color="light" light expand="md">
             <div class="col-3" style={{padding: '0px'}}>
                <Logo style={{width: '100%', maxHeight:'70px'}} />
              </div>
              <div class="col-9 text-right" style={{padding: '0px'}}>
              <a href="javascript:void(0)" onClick={this.logoutPopup} className='text-chattamee mx-2' style={{display: "inline-block"}}> 
                <Logout style={{height: '32px', width: '32px'}} />
                <br />
                <span style={{fontSize:"11px"}}>Logout</span>
              </a>
              <a href='javascript:void(0)' className='text-chattamee' style={{display: "inline-block", textAlign:"center"}}  onClick={(e) => this.editpwd.open()}>
                <Chiave style={{height: '32px', width: '32px'}} />

                <br />
                <span style={{fontSize:"11px"}}><div>Cambia</div><div style={{    marginTop: '-6px'}}>password</div></span>
              </a>
              <Dropdown style={{display: 'inline-block'}} isOpen={this.state.DropDownUserChat} toggle={this.toggleDropDownChat}>
              <DropdownToggle style={{backgroundColor: 'transparent',border:0, marginTop: '-35px'}}>
              <a href="javascript:void(0)" className='text-chattamee' style={{display: "inline-block"}}>
                <Chatroom style={{height: '32px', width: '32px'}} />
                <br />
                <span style={{fontSize:"11px"}}>ChatRoom</span>
              </a>
              </DropdownToggle>
              <DropdownMenu right='false' style={{width: '300px', right:'9px'}}>
              <DropdownItem header>Accedi alla chat</DropdownItem>
              {this.renderListaChat()}
              <DropdownItem onClick={this.nuovoCodice}>Inserisci un nuovo codice</DropdownItem>
              </DropdownMenu>
              </Dropdown>
    
              </div>
          </Navbar>
        </header>
        
            {page =='lista_profili' ? this.listaProfili() : this.nuovoProfilo()}
         
        <section>
        </section>
      </div>
      );
    }
  }

  export default SelectProfile;
