import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import TagList from './TagList';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class RicercaAvanzata extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      modal: false,
	      testo_ricerca: props.testoRicerca,
	      sesso: props.sesso,
	      stato: props.stato,
	      selectedTag: props.selectedTag,
	    };
	    this.UserApi = new UserApi();
	}

	open = () => {
		this.setState({
			modal: true
		})
	}
	close = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	toggle = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	  handleChange = (e) => {

	    const { name, value } = e.target;

	    this.setState({ 
	        [name]: value,
	      }
	    );
	  }

	  handleSubmit = () => {
	  	const { onSearch } = this.props;
	  	const selectedTag = this.tagManager.getListaTagSelezionati();
	  	const { testo_ricerca, sesso, stato } = this.state;
	  	onSearch(selectedTag, testo_ricerca, sesso, stato);
	  }
	render() {
		const { tag } = this.props;
		const { testo_ricerca, sesso, stato, selectedTag } = this.state;
		console.log(tag);
		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
				 <ModalHeader toggle={this.toggle}>Ricerca avanzata</ModalHeader>
				 <ModalBody>
					Seleziona uno o più tag con cui vuoi effettuare la ricerca
					<TagList tag={tag} selectedTag={selectedTag} ref={ TagList => { this.tagManager = TagList; }} />

					<FormGroup>
			          <Label for="testo_ricerca">Inserisci del testo di ricerca</Label>
			          <Input type="text" value={testo_ricerca} onChange={this.handleChange}  name="testo_ricerca" id="testo_ricerca" placeholder="Ricerca su nickname, profilo e status" />
			        </FormGroup>
			        <FormGroup>
			        	<Label for="sesso">Sesso</Label>
			        	<Input type='select' id="sesso" className="form-control" value={sesso} name="sesso" onChange={this.handleChange}>
			        		<option value=''>Indifferente</option>
			        		<option value="M">Maschi</option>
			        		<option value="F">Femmine</option>
			        	</Input>
			        </FormGroup>

			        <FormGroup>
			        	<Label for="stato">Stato</Label>
			        	<Input type='select' id="stato" className="form-control" value={stato} name="stato" onChange={this.handleChange}>
			        		<option value=''>Tutti</option>
			        		<option value="1">Online</option>
			        		<option value="0">Offline</option>
			        	</Input>
			        </FormGroup>
				 </ModalBody>
				 <ModalFooter>
				  <Button color="success" onClick={this.handleSubmit}>Cerca</Button>{' '}
	            	<Button color="secondary" onClick={this.toggle}>Annulla</Button>
	            </ModalFooter>
			</Modal>
		)
	}
}

export default RicercaAvanzata;