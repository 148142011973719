import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand } from 'reactstrap';
import { PrivateRoute } from './Component/PrivateRoute.js'; 
import Routing from './Component/Routing.js'; 

import SelectChat  from './Pages/Login/SelectChat.js';

import SelectProfile  from './Pages/Login/SelectProfile.js';
import HomePage  from './Pages/Chat/HomePage.js';
import Login  from './Pages/Login/login.js';
class App extends React.Component {
  render(){
    return (
      <div>
      
      <Router>
        <div>
          <Routing />
        </div>
      </Router>
      
      </div>
      );
    }
  }

  export default App;
