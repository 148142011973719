import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
class Loader extends React.Component {
	constructor(props) {
	    super(props);
	}

	render(){
		console.log(this.props.loading);
		let display = this.props.loading?"block":"none";
		return (
			<div style={{display}}>
				<div style={{position:'fixed', width:'100%',height:'100%',top:0,bottom:0,backgroundColor: 'black',zIndex: 999999999,color:'white',opacity: 0.2}}>
					
				</div>
				<div style={{position:'fixed', width:'100%',height:'100%',top:0,bottom:0,zIndex: 9999999999,color:'white',textAlign:"center"}}>
				<FontAwesomeIcon style={{position: 'absolute',top:'50%'}} icon={faSpinner} spin={true} size="2x" />
				</div>
			</div>
		)
	}
}

export default Loader;