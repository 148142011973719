import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import {ReactComponent as EditProfilo}  from'../../Images/EditProfilo.svg';
import Spinner from '../../Helper/Spinner'

class Profilo extends React.Component {
	constructor(props) {
	    super(props);
    
	}

	

	goEdit = (id_profilo) => {
		
		console.log("edit");
		console.log(id_profilo);
		const { onEdit } = this.props;
		onEdit(id_profilo)

	}
	selectProfile = (id_profilo, user_id) => {
		console.log("select");

		const { onSelect } = this.props;
		onSelect(id_profilo)

	}
	render(){
		const { data, onEdit } = this.props;
		let {sesso} = data;
		if(sesso == null){
			sesso = "";
		}
		let img = `${Config.apiPath}/image/avatar${sesso}.png`;
		if(data.immagine_profilo != null)
		{
			img = `${Config.apiPath}/image/${data.immagine_profilo}`;
		}
		return(
			<div style={{position: 'relative',fontFamily:'Roboto', fontWeight: 100}}>
			 <div style={{cursor: 'pointer'}} onClick={(e) => this.selectProfile(data.id_profilo)} class="text-chattamee no-decoration">
                <div class="chat_list">
                  <div class="chat_people">
                  <div class="chat_img"> <img src={img} alt="sunil"  style={{borderRadius: '50%'}} /> </div>
                  <div class="chat_ib">
                    <h5 style={{fontFamily:'Roboto', fontWeight: 100}}>Nickname: {data.nickname} </h5>
                    <small style={{fontFamily:'Roboto', fontWeight: 100}}>{data.nome_profilo}</small>
                  </div>
                  </div>
                </div>
              </div>
              <div style={{cursor: 'pointer', position:'absolute', top:'45%', right:'5%'}} refid={data.id_profilo} className='pull-right' onClick={(e) => this.goEdit(data.id_profilo)}>
              	<EditProfilo style={{height: '32px', width: '32px'}} />
              </div>
              </div>
			)
	}
}

export default Profilo;