import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Storage from '../Helper/Storage'

export const PrivateRoute = ({ component: Component, ...rest }) => {
	console.log(rest);
	return (

    <Route {...rest} render={(props) => {
    	console.log(props);
    	console.log(rest);
    	var st = new Storage();
    	return getRoute({...props,...rest}, Component)
    	}}
     />
)}


const getRoute = (props, Component) => {
	console.log(props);
	var st = new Storage();
	//var ls = new SecureLS();
	if(!st.getItem('token')){
		return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
	}

	if(!st.getItem('chatData') && props.location.pathname != '/selectChat'  && props.location.pathname != '/tokenLogin')
	{
		return <Redirect to={{ pathname: '/selectChat', state: { from: props.location } }} />;
	}else if(!st.getItem('profile_selected') && props.location.pathname != '/selectProfile' && props.location.pathname != '/selectChat' && props.location.pathname != '/tokenLogin')
	{
		return <Redirect to={{ pathname: '/selectProfile', state: { from: props.location } }} />;
	}
	return <Component {...props} />;
}