import React from 'react';
import '../../App.css';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import {ReactComponent as Chiave}  from'../../Images/Chiave.svg';
import {ReactComponent as Logo}  from'../../Images/Logo.svg';
import {ReactComponent as Logout}  from'../../Images/Logout.svg';
import {ReactComponent as CodiceChat}  from'../../Images/CodiceChat.svg';
import EditPassword from '../PageComponents/EditPassword';
import ChatDisclaimer from '../PageComponents/ChatDisclaimer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUserCircle, faKey } from '@fortawesome/free-solid-svg-icons'
import { Button,Navbar,NavbarBrand,InputGroup,InputGroupAddon,Col,Row, Form, FormGroup, Label, Input,Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
class SelectChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        codice_chat: '',
        submitted: false,
        loading: false,
        error: '',
        DropDownUserOpen: false,
    };
    this.UserApi = new UserApi();
    this.Storage = new Storage();
    document.body.classList.remove('isLogin');
    document.body.classList.add('isSelectChat');
  }

  toggleDropDownUser = () => {
    this.setState(prevState => ({
      DropDownUserOpen: !prevState.DropDownUserOpen
    }));
  }

  handleChange = (e) => {

    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    this.setState({ 
        [name]: value,
      }
    );
  }

  handleSubmit = () => {
    this.setState({ submitted: true });
     const { codice_chat, returnUrl } = this.state;

      if (!(codice_chat)) {
          return;
      }
      this.setState({ loading: true, error: false });
      this.props.onChangeLoader(true);
      this.UserApi.setChatCode(codice_chat, (success,data) => {
        this.props.onChangeLoader(false);
         if(!success)
        {
          this.setState({
            error: data,
            loading: false,
          });
        }else{
          let chat = {
            codiceChat: codice_chat,
            name: data.chat,
            chat_type: data.chat_type
          };
          this.Storage.setItem('chatData', JSON.stringify(chat));
          if(data.chat_disclaimer_accepted == 0 && data.disclaimer != "") {
            this.setState({ loading: false, error: false });
            this.chtDisclaimer.open(data.disclaimer);
          }else{
            
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
          }
         
        } 
   
        }
      );
  }

  acceptedDisclaimer = () => 
  {
    this.UserApi.acceptDisclaimer(()=>{
      const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);

    });
  }
 
  render(){
    const { codice_chat, loading, error } = this.state;
    const { onLogout } = this.props;
    
    return (
      <div className="App">
      <EditPassword ref={ EditPassword => { this.editpwd = EditPassword; }} />
      <ChatDisclaimer onAccepted={this.acceptedDisclaimer} ref={ ChatDisclaimer => { this.chtDisclaimer = ChatDisclaimer; }} />
         <header>
          <Navbar color="white" light expand="md">
          
              <div class="col-sm-12 text-right">
                  
                  
              <a href='javascript:void(0)' className='text-chattamee mx-2' style={{display: "inline-block", textAlign:"center"}}  onClick={(e) => this.editpwd.open()}>
                <Chiave style={{height: '32px', width: '32px'}} />
                <br />
                <span style={{fontSize:"11px"}}>Cambia password</span>
              </a>
              <a href="javascript:void(0)" onClick={onLogout} className='text-chattamee mx-2' style={{display: "inline-block"}}> 
                <Logout style={{height: '32px', width: '32px'}} />


                <br />
                <span style={{fontSize:"11px"}}>Logout</span>
              </a>
              </div>
            
          </Navbar>
        </header>
        <Row className='mx-2'>
         
            <Col md={{ size: 4}} className='mx-auto mt-5'>
              <div>
               <Logo style={{    width: '80%', margin: '10%'}} />
              <Col>
                <Form>
                  <InputGroup  className='red'>
                    <InputGroupAddon addonType="prepend" >
                      <span class="input-group-text bg-chattamee text-white" id="basic-addon1">
                        <CodiceChat style={{height: '20px'}} />
                      </span>
                    </InputGroupAddon>
                    <Input type="codice_chat" value={codice_chat} onChange={this.handleChange}  name="codice_chat" id="codice_chat" placeholder="Inserisci il codice della chat" />
                  </InputGroup>

                  <Button type='button' onClick={this.handleSubmit}  className="btn bg-chattamee text-white btn-block mt-4 mb-4">Entra in chat</Button>
                   {loading &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                  <br />
                  {error &&
                        <div className={'alert alert-danger'}>{error}</div>
                    }
                  <br />
                </Form>
                </Col>
              </div>
            </Col>
          
        </Row>
        <section>
        </section>
      </div>
      );
    }
  }

  export default SelectChat;
