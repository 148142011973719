import React from 'react';
import '../../App.css';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import {ReactComponent as LogoWhite}  from'../../Images/Logowhite.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faKey } from '@fortawesome/free-solid-svg-icons'
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon  } from 'reactstrap';
class RecuperaPassword extends React.Component {
  constructor(props) {
  	super(props);
  	this.state = {
  		email: '',
        submitted: false,
        loading: false,
        error: '',
        page: "inserimento_email",
        hash: "",
        password: "",
        conferma_password: "",  
  	};
    this.UserApi = new UserApi();
    this.Storage = new Storage();
    document.body.classList.add('isLogin');
    document.body.classList.remove('isSelectChat');
  }

  handleChange = (e) => {

    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    this.setState({ 
        [name]: value,
      }
    );
  }

  handleSubmitRecupera = () => {
     const { hash,password,conferma_password } = this.state;
     let error = null;
     if(hash == "")
     {
      error = "Il campo hash è obbligatorio";
     }
     if(password == "")
     {
      error = "Il campo nuova password è obbligatorio";
     }
     if(password != conferma_password)
     {
      error = "I campi password e conferma password devono coincidere"
     }
     if(error != null)
     {
      this.setState({
          error,
          loading: false,
        });
     }

     this.setState({ loading: true, error: false });
      this.UserApi.resetPassword(password, hash, (success,data) =>{
        if(data.password_edited)
        {
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }else{
          this.setState({
            error: "Attenzione, il codice hash inseritpo è errato",
            loading: false,
          });
        }
      });
  }

  handleSubmit = () => {
    this.setState({ submitted: true });
     const { email, returnUrl } = this.state;

      if (!(email)) {
          return;
      }
      this.setState({ loading: true, error: false });
      this.UserApi.recuperaPassword(email, (success,data) =>{
        if(data.mail_exist == false)
        {
          this.setState({
            error: "Attenzione, l'indirizzo email è inesistente",
            loading: false,
          });
        }else{
          this.setState({page: "recupera_password", loading: false});
        }
      })


  }

  renderEmail(){
    const { email, loading, error } = this.state;
      return (
    <div className="">
   
        <Row className='mx-2'>
         
            <Col md={{ size: 4}} className='mx-auto mt-5'>
              <div>
              <LogoWhite style={{    width: '80%', margin: '10%'}} />
              <Col>
                <Form>
                  <InputGroup className='mb-3'>
                    <InputGroupAddon addonType="prepend">
                      <span class="input-group-text bg-chattamee text-white" id="basic-addon1">
                        <FontAwesomeIcon icon={faAt}  />
                      </span>
                    </InputGroupAddon>

                    <Input type="email" value={email} onChange={this.handleChange}  name="email" id="email" placeholder="Indirizzo email con il quale ti sei registrato" />
                  </InputGroup>
              <a href="/login">Torna alla login</a>
                  <Button type='button' onClick={this.handleSubmit} className="btn bg-chattamee text-white btn-block mt-4 mb-4">Recupera password</Button>
                   {loading &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                  <br />
                  {error &&
                        <div className={'alert alert-danger'}>{error}</div>
                    }
                  <br />
                </Form>
                </Col>
              </div>
            </Col>
          
        </Row>
        <section>
        </section>
      </div>
      );
  }

  recuperaPassword(){
    const { hash,password,conferma_password, loading, error } = this.state;
      return (
    <div className="">
   
        <Row className='mx-2'>
         
            <Col md={{ size: 4}} className='mx-auto mt-5'>
              <div>
              <LogoWhite style={{    width: '80%', margin: '10%'}} />
              <Col>
                <Form>
                  <InputGroup className='mb-3'>
               

                    <Input type="text" value={hash} onChange={this.handleChange}  name="hash" id="hash" placeholder="Inserisci il codice ricevuto per email" />
                  </InputGroup>

                  <InputGroup className='mb-3'>
                    <InputGroupAddon addonType="prepend">
                      <span class="input-group-text bg-chattamee text-white" id="basic-addon1">
                        <FontAwesomeIcon icon={faKey}  />
                      </span>
                    </InputGroupAddon>

                    <Input type="password" value={password} onChange={this.handleChange}  name="password" id="password" placeholder="Inserisci una nuova password" />
                  </InputGroup>


                  <InputGroup className='mb-3'>
                    <InputGroupAddon addonType="prepend">
                      <span class="input-group-text bg-chattamee text-white" id="basic-addon1">
                        <FontAwesomeIcon icon={faKey}  />
                      </span>
                    </InputGroupAddon>

                    <Input type="password" value={conferma_password} onChange={this.handleChange}  name="conferma_password" id="conferma_password" placeholder="Conferma la nuova password" />
                  </InputGroup>
              <a href="/login">Torna alla login</a>
                  <Button type='button' onClick={this.handleSubmitRecupera} className="btn bg-chattamee text-white btn-block mt-4 mb-4">Reimposta password</Button>
                   {loading &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                  <br />
                  {error &&
                        <div className={'alert alert-danger'}>{error}</div>
                    }
                  <br />
                </Form>
                </Col>
              </div>
            </Col>
          
        </Row>
        <section>
        </section>
      </div>
      );
  }

  render(){
    const { page } = this.state;
    if(page == "inserimento_email")
    {

  	  return this.renderEmail();
    }else if(page == "recupera_password"){
      return this.recuperaPassword();
    }
  }

}

  export default RecuperaPassword;