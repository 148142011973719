import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import SingleMessage from './SingleMessage';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'
class TagList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTag:props.selectedTag!==undefined?props.selectedTag:[]
    }
  }

  toggleSelect = (tagID) => {
    let { selectedTag } = this.state;
    let index = selectedTag.indexOf(tagID);
    if(index >= 0) {
      selectedTag.splice(index, 1);
    }else{
      selectedTag.push(tagID);
    }
    this.setState({selectedTag});
  }
  getListaTagSelezionati = () => {
    const { selectedTag } = this.state;
    return selectedTag;
  }
  render() {
    const {tag} = this.props
    const { selectedTag } = this.state;
    let tagHTML = [];

    for(let i in tag)
    {
      let badgeColor = selectedTag.indexOf(tag[i].id_tag)>=0?'badge badge-chat':'badge badge-default'
      tagHTML.push(<label style={{cursor: 'pointer'}} onClick={(e) => this.toggleSelect(tag[i].id_tag)} className={badgeColor}><h5>{tag[i].tag}</h5></label>);
    }
    return tagHTML;
  }

}
export default TagList;