import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute.js'; 
import Login  from '../Pages/Login/login.js';
import Registrazione  from '../Pages/Login/Registrazione.js';
import RecuperaPassword  from '../Pages/Login/RecuperaPassword.js';
import SelectChat  from '../Pages/Login/SelectChat.js';
import SelectProfile  from '../Pages/Login/SelectProfile.js';
import SetChatCode  from '../Helper/SetChatCode.js';
import TokenLogin  from '../Helper/TokenLogin.js';
import HomePage  from '../Pages/Chat/HomePage.js';
import Storage from '../Helper/Storage';
import Loader from '../Pages/PageComponents/Loader';
class Routing extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      
        loading: false,
       
    };

    
  }
  logout = () =>{
    let s = new Storage();
    s.removeAllItems();
    window.location.reload();
  }

  changeLoader = (loading) => {

    this.setState({loading})
  }
  render(){
   
    return (
      
      
      <Router>
        <Loader loading={this.state.loading} />
        <div>
          <PrivateRoute exact path="/" onLogout={this.logout} component={HomePage} onChangeLoader={this.changeLoader} />
          <PrivateRoute exact path="/chat/:id_user" onLogout={this.logout} component={HomePage} onChangeLoader={this.changeLoader} />
          <PrivateRoute exact path="/selectChat" onLogout={this.logout} component={SelectChat} onChangeLoader={this.changeLoader} />
          <PrivateRoute exact path="/SelectProfile" page="lista_profili" onLogout={this.logout} component={SelectProfile} onChangeLoader={this.changeLoader} />
          <PrivateRoute exact path="/SelectProfile/newProfile" page="Nuovo profilo" onLogout={this.logout} component={SelectProfile} onChangeLoader={this.changeLoader} />
          <PrivateRoute exact path="/SelectProfile/editProfile/:id_profilo" page="GO Modifica profilo" onLogout={this.logout} component={SelectProfile} onChangeLoader={this.changeLoader} />
          
          <Route path="/login" render={(props) => <Login {...props} onChangeLoader={this.changeLoader} />} />
          
          <Route path="/registrazione" render={(props) => <Registrazione {...props} onChangeLoader={this.changeLoader} />} />
          
          <Route path="/recuperaPassword" render={(props) => <RecuperaPassword {...props} onChangeLoader={this.changeLoader} />} />
          
          <Route path="/code/:chatcode" render={(props) => <SetChatCode {...props} onChangeLoader={this.changeLoader} />} />
          
          <Route path="/tokenLogin/" render={(props) => <TokenLogin {...props} onChangeLoader={this.changeLoader} />} />

        </div>
      </Router>
      
     
      );
    }
  }

  export default Routing;
