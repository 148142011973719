import React from 'react';
import Config from '../Config/Config';
import Storage from './Storage';

const axios = require('axios');
const qs = require('qs');

 
class Api {
	constructor()
	{
		this.Storage = new Storage();
	}

	link = (path, params, callback) => {
		let url = Config.apiPath+path;
		let body = null
		let contentType = 'application/json'
		let config = {
		  headers: {
		    'Content-Type': 'application/json'
		  }
		}
 
		if(this.Storage.getItem('token'))
		{
			config.headers["Authorization"] = "Bearer "+this.Storage.getItem('token');
		}

		axios({
			method:"link",
			url,
			params,
			headers:config.headers,
		})
	  .then((result) => {
		   callback(true,result.data);
		  })
		  .catch((err) => {
		  	let error = null;
		  	if(err.response && err.response.data && err.response.data.error_description)
		  	{
		  		error = err.response.data.error_description
		  		
		  	}else if(err.response && err.response.data && err.response.data.message && err.response.data.message.error){
		  		error = err.response.data.message.error 
		  	}
		    callback(false,error);
		  });
	}

	patch = (path, params, callback) => {
		let url = Config.apiPath+path;
		let body = null
		let contentType = 'application/json'
		let config = {
		  headers: {
		    'Content-Type': 'application/json'
		  }
		}

		if(this.Storage.getItem('token'))
		{
			config.headers["Authorization"] = "Bearer "+this.Storage.getItem('token');
		}

	
		axios.patch(url, params, config)
		  .then((result) => {
		   callback(true,result.data);
		  })
		  .catch((err) => {
		  	let error = null;
		  	if(err.response && err.response.data && err.response.data.error_description)
		  	{
		  		error = err.response.data.error_description
		  		
		  	}else if(err.response && err.response.data && err.response.data.message && err.response.data.message.error){
		  		error = err.response.data.message.error 
		  	}
		    callback(false,error);
		  });
	}

	get = (path, params, callback) => {
		let url = Config.apiPath+path;
		let body = null
		let contentType = 'application/json'
		let config = {
		  headers: {
		    'Content-Type': 'application/json'
		  }
		}

		if(this.Storage.getItem('token'))
		{
			config.headers["Authorization"] = "Bearer "+this.Storage.getItem('token');
		}

	
		axios.get(url, config)
		  .then((result) => {
		   callback(true,result.data);
		  })
		  .catch((err) => {
		  	let error = null;
		  	if(err.response && err.response.data && err.response.data.error_description)
		  	{
		  		error = err.response.data.error_description
		  		
		  	}else if(err.response && err.response.data && err.response.data.message && err.response.data.message.error){
		  		error = err.response.data.message.error 
		  	}
		  	
		    callback(false,error);
		  });
	}

	post  = (path, params, callback, inForm) => {
		let url = Config.apiPath+path;
		let body = null
		let contentType = 'application/json'
		let config = {
		  headers: {
		    'Content-Type': 'application/json'
		  }
		}
		if(inForm){
			params = qs.stringify(params)
			config = {
			  headers: {
			    'Content-Type': 'application/x-www-form-urlencoded'
			  }
			}

			
			
		}
		if(this.Storage.getItem('token'))
			{
				config.headers["Authorization"] = "Bearer "+this.Storage.getItem('token');
			}

		console.log(config);
		axios.post(url, params, config)
		  .then((result) => {
		   callback(true,result.data);
		  })
		  .catch((err) => {
		  	console.log(err);
		  	let error = null;
		  	if(err.response && err.response.data && err.response.data.error_description)
		  	{
		  		error = err.response.data.error_description
		  		
		  	}else if(err.response && err.response.data && err.response.data.message && err.response.data.message.error){
		  		error = err.response.data.message.error 
		  	}
		    callback(false,error);
		  })

	}

	oauthLogin = (params, callback) => {
		params.grant_type  = "password";
		params.client_id  = Config.clientId;
		params.client_secret = Config.clientSecret;
		//params[]
		this.post('oauth/login',params, callback,true);
	}
}

  export default Api;