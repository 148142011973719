import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import TagList from './TagList';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class DettagliProfilo extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      modal: false,
	    };
	}
	open = () => {
		this.setState({
			modal: true
		})
	}
	close = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	toggle = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	  	render() {
		const { profilo } = this.props;
		if(profilo == null)
		{
			return (<div />);
		}
		let { sesso } = profilo;
		if(sesso == null){
			sesso = "";
		}
		let img = `${Config.apiPath}/image/avatar${sesso}.png`;
		if(profilo.immagine_profilo != null)
		{
			img = `${Config.apiPath}/image/${profilo.immagine_profilo}`;
		}
		let sessi = {
			M: "Uomo",
			F: "Donna",
		}
		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
				 <ModalHeader toggle={this.toggle}>Dettagli Profilo</ModalHeader>
				 <ModalBody>
				 <div class="row">
                  <div className="col-sm-3">
					  <img src={img} alt="sunil"  style={{borderRadius: '50%',width:'100%'}} /> 
				</div>
					 <div class="col-sm-9">
                    <h5>{profilo.nickname} </h5>
                    <small>{profilo.stato}</small>
                  </div>
                  </div>
                  <div class="row">
	                  <div class="col-sm-12">
	                 	{profilo.descrizione}
	                  
	                  	{sesso != "" ? (<div><strong>Genere:</strong> {sessi[sesso]}</div>) : ""}
	                  	{profilo.telefono != "" ? (<div><strong>Telefono:</strong> {profilo.telefono}</div>) : ""}
	                  	{profilo.eta != null && profilo.mostra_eta==1 ? (<div><strong>Età:</strong> {profilo.eta}</div>) : ""}
	                  </div>
                  </div>
                  
				 </ModalBody>
				 <ModalFooter>
				  
	            	<Button color="secondary" onClick={this.toggle}>Ok</Button>
	            </ModalFooter>
			</Modal>
		)
	}

}

export default DettagliProfilo;