import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import TagList from './TagList';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class LogoutPopup extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      modal: false,
	    };
	}
	open = () => {
		this.setState({
			modal: true
		})
	}
	close = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	toggle = () => {
	    this.setState(prevState => ({
	      modal: false
	    }));
	  }

	  	render() {

		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
				 <ModalHeader toggle={this.toggle}>Esci</ModalHeader>
				 <ModalBody>
				 
                  	Vuoi effettuare il logout oppure vuoi semplicemente cambiare chat?
				 </ModalBody>
				 <ModalFooter>
				  
	            	<Button color="primary" onClick={this.props.onChangeChat}>Cambia Chat</Button>
	            	<Button color="primary" onClick={this.props.onLogout}>Logout</Button>
	            </ModalFooter>
			</Modal>
		)
	}

}

export default LogoutPopup;