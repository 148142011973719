import React from 'react';


import Storage from '../Helper/Storage';
import UserApi from '../Helper/Api/UserApi';


class SetChatCode extends React.Component {
  constructor(props) {
  	super(props);;
    this.UserApi = new UserApi();
    this.Storage = new Storage();

    
    const token = this.Storage.getItem("token");
    console.log(token);
    console.log(token != "");
    if(token != undefined && token != ""  && token != null)
    {
      this.UserApi.setChatCode(props.match.params.chatcode, (success,data) => {
        
        if(!success)
        {
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }else{
          let chat = {
            codiceChat: props.match.params.chatcode,
            name: data.chat,
            chat_type: data.chat_type

          }
          this.Storage.setItem('chatData', JSON.stringify(chat));
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        } 
   
        });
    }else{
      this.Storage.setItem("requiredChat",props.match.params.chatcode);
      const { from } = this.props.location.state || { from: { pathname: "/" } };
      this.props.history.push(from);
    }
    
    //if(this.Storage.getItem("token"))
  }

  

  render(){
    return null;
  }

}

  export default SetChatCode;