import React from 'react';
import Config from '../../Config/Config';

class SingleMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  renderIncomingMessage = () => {
  	const { message, destinatario } = this.props;
  	const { messaggio } = message;
  	let {sesso} = destinatario;
	if(sesso == null){
		sesso = "";
	}
  	let img = `${Config.apiPath}/image/avatar${sesso}.png`;
	if(destinatario.immagine_profilo != null)
	{
		img = `${Config.apiPath}/image/${destinatario.immagine_profilo}`;
	}
  	return (
  		<div className="incoming_msg">
  			<div className="incoming_msg_img">
  				<img src={img} style={{borderRadius: '50%'}}/>
  			</div>
  			<div className="received_msg">
  				<div className="received_withd_msg">
  					<p>{messaggio}</p>
            <span className="time_date">{this.parseTime(message.timestamp)}</span>
  				</div>
  			</div>
  		</div>
  	)
  }

  renderAdminMessage = () => {
      const { message, destinatario } = this.props;
    const { messaggio } = message;
    let {sesso} = destinatario;
  if(sesso == null){
    sesso = "";
  }
    let img = `${Config.apiPath}/image/avatar${sesso}.png`;
  if(destinatario.immagine_profilo != null)
  {
    img = `${Config.apiPath}/image/${destinatario.immagine_profilo}`;
  }
    return (
      <div className="admin_msg">
     
        <div className="received_msg">
          <div className="admin_withd_msg">
            
            <p>
            <strong style={{fontSize: "10px"}}>MESSAGGIO DI SISTEMA</strong><br />
            {messaggio}</p>
            <span className="time_date">{this.parseTime(message.timestamp)}</span>
          </div>
        </div>
      </div>
    )
  }

  renderOutcomingMessage = () => {
  	const { message, mittente } = this.props;
  	const { messaggio } = message;
  	let {sesso} = mittente;
	if(sesso == null){
		sesso = "";
	}
  	let img = `${Config.apiPath}/image/avatar${sesso}.png`;
	if(mittente.immagine_profilo != null)
	{
		img = `${Config.apiPath}/image/${mittente.immagine_profilo}`;
	}
  	return (
  		<div className="outgoing_msg">
  			<div className="outcoming_msg_img">
  				<img src={img} style={{ borderRadius: '50%' }}/>
  			</div>
  			<div className="sent_msg">
  				<p>{messaggio}</p>
          <span className="time_date">{this.parseTime(message.timestamp)}</span>
  			</div>
  			
  		</div>
  	)
  }

  parseTime = (sTime) => {

    const datetime = new Date(sTime);
    const now = new Date();
    let hour = parseInt(datetime.getHours());
    let min = parseInt(datetime.getMinutes());
    if(hour < 10)
    {
      hour = `0${hour}`;
    }
    if(min < 10)
    {
      min = `0${min}`;
    }
    let month = datetime.getMonth();
    let day = datetime.getDate();

    let mesi = [];
    mesi.push("Gen");
    mesi.push("Feb");
    mesi.push("Mar");
    mesi.push("Apr");
    mesi.push("Mag");
    mesi.push("Giu");
    mesi.push("Lug");
    mesi.push("Ago");
    mesi.push("Set");
    mesi.push("Ott");
    mesi.push("Nov");
    mesi.push("Div");
    let date = `${day} ${mesi[month]}`;
    if(now.getFullYear() != datetime.getFullYear())
    {
      let year = datetime.getFullYear();
      date = `${day} ${mesi[month]} ${year}`;
    }
    if(now.getMonth() == datetime.getMonth() && now.getFullYear() == datetime.getFullYear() && now.getDate() == datetime.getDate())
    {
      date = "Oggi";
    }

    now.setDate(now.getDate() - 1);
    if(now.getMonth() == datetime.getMonth() && now.getFullYear() == datetime.getFullYear() && now.getDate() == datetime.getDate())
    {
      date = "Ieri";
    }


    return `${date} ${hour}:${min}`;
  }
  render() {
  	const { message } = this.props;
  	const { type, messaggio } = message;;
    if(type == 1)
    {
      return this.renderOutcomingMessage();
    }else if(type == 2){
      return this.renderIncomingMessage();
    }else{
      return this.renderAdminMessage();
    }
  	
  }

}


export default SingleMessage;
