import React from 'react';
import '../../App.css';
import Config from '../../Config/Config';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCircle } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../Helper/Spinner'

class ChatUsers extends React.Component {
	constructor(props) {
	    super(props);
	    this.Storage = new Storage();
	}

	
	selectProfile = (id_user, user_id) => {

		const { onSelect } = this.props;
		onSelect(id_user)

	}

	showProfile = (event, id_profilo) => {
		const { onShowProfilo } = this.props;
		event.stopPropagation();
		onShowProfilo(id_profilo);
	}
	render(){
		const { data, onEdit } = this.props;
		console.log(data);
		let {sesso} = data;
		if(sesso == null){
			sesso = "";
		}
		console.log(data);
		let img = `${Config.apiPath}/image/avatar${sesso}.png`;
		if(data.immagine_profilo != null)
		{
			img = `${Config.apiPath}/image/${data.immagine_profilo}`;
		}
		let nuoviMessaggi = null;
		if(data.da_leggere != undefined && data.da_leggere > 0)
		{
			nuoviMessaggi = <div><strong>{data.da_leggere} nuovi messaggi</strong></div>;
		}

		let eta = null;
		if(data.eta != null && data.mostra_eta==1)
		{
			eta = <div style={{display:"inline-block"}}> ({data.eta})</div>;
		}
		let tag = [];
		let tags = data.tags[JSON.parse(this.Storage.getItem("chatData")).chat_type];
		for(var i in tags){
			tag.push(<label class='badge badge-chat'>{tags[i].tag}</label>);
		}

		return(
			 <div style={{cursor: 'pointer'}} onClick={(e) => this.selectProfile(data.fk_id_user)} class="text-chattamee no-decoration">
                <div class="chat_list">
                  <div class="chat_people">
                  <div class="chat_img"> <img src={img} alt=""  onClick={(e) => this.showProfile(e, data.fk_id_profilo)}  style={{borderRadius: '50%'}} /> </div>
                  <div class="chat_ib">
                    <h5><FontAwesomeIcon icon={faCircle} className={data.online?'text-success':'text-danger'}/> {data.nickname}
                    {eta}</h5>
                    <p>{data.status}</p>
                    {tag?<div>{tag}</div>:null}
                    {nuoviMessaggi}
                  </div>
                  </div>
                </div>
              </div>
			)
	}
}

export default ChatUsers;