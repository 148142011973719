import React from 'react';
import Securels from 'secure-ls'

class Storage {
	constructor() {
		this.secureStorage = new Securels({encodingType: 'aes'});

	}

	setTimestamp = () => {
		let  dt = new Date();

		const lastTimestamp = localStorage.getItem("cht-timestamp");
		if(lastTimestamp == null || lastTimestamp < dt)
		{
			this.removeAllItems();
		}

		dt.setHours( dt.getHours() + 2 );
		
		let hour = dt.getTime();
		
		localStorage.setItem(`cht-timestamp`, hour);
	}
	getItem = (key) => {
		this.setTimestamp();
		//return sessionStorage.getItem(key);
		return localStorage.getItem(`cht-${key}`)
	}

	setItem = (key, value) =>{
		this.setTimestamp();
		localStorage.setItem(`cht-${key}`, value);
		 
	}

	removeItem = (key, value) =>{
		this.setTimestamp();
		localStorage.removeItem(`cht-${key}`, value);
		 
	}



	

	removeAllItems = () => {
		localStorage.clear();
	}
}

export default Storage;