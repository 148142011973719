import React from 'react';
import '../../App.css';
import {ReactComponent as LogoWhite}  from'../../Images/Logowhite.svg';
import UserApi from '../../Helper/Api/UserApi';
import Storage from '../../Helper/Storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styleColor.css';
import '../../Styles/login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faKey } from '@fortawesome/free-solid-svg-icons'
import { Button,Navbar,NavbarBrand,Card,Col,Row, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon  } from 'reactstrap';
class Login extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
        email: '',
        password: '',
        submitted: false,
        loading: false,
        error: ''
    };
    this.UserApi = new UserApi();
    this.Storage = new Storage();
    document.body.classList.add('isLogin');
    document.body.classList.remove('isSelectChat');
    
  }
/*
  shouldComponentUpdate = () => {
    console.log(this.props);
    this.props.onChangeLoader(this.state.loading);
  }
  */

  handleChange = (e) => {

    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    this.setState({ 
        [name]: value,
      }
    );
  }

  handleSubmit = () => {
    this.setState({ submitted: true });
     const { email, password, returnUrl } = this.state;

      if (!(email && password)) {
          return;
      }
      this.setState({ loading: true, error: false });
      this.props.onChangeLoader(true);
      this.UserApi.login(email,password,(success,data) => {
        console.log(success);
        console.log(data);
        this.props.onChangeLoader(false);
        if(!success)
        {
          this.setState({
            error: data,
            loading: false,
          });
        }else{
    
          this.Storage.setItem('token', data.access_token);
          this.Storage.setItem('email', email);
          const requiredChat = this.Storage.getItem('requiredChat');
          
          if(requiredChat != null && requiredChat != "")
          {
            this.UserApi.setChatCode(requiredChat, (success,data) => {
              if(!success)
              {
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
              }else{
                let chat = {
                  codiceChat: requiredChat,
                  name: data.chat,
                  chat_type: data.chat_type

                }
                this.Storage.setItem('chatData', JSON.stringify(chat));
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
              } 
         
              });
          }else{
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
          }
          
       
        }
      });
  }

  render(){
     console.log(this.props);
    const { email, password, loading, error } = this.state;
    console.log(this.state);
    return (
      <div className="">
   
        <Row className='mx-2'>
         
            <Col md={{ size: 4}} className='mx-auto mt-5'>
              <div>
              <LogoWhite style={{    width: '80%', margin: '10%'}} />
              <Col>
                <Form>
                  <InputGroup className='mb-3'>
                    <InputGroupAddon addonType="prepend">
                      <span class="input-group-text bg-chattamee text-white" id="basic-addon1">
                        <FontAwesomeIcon icon={faAt}  />
                      </span>
                    </InputGroupAddon>

                    <Input type="email" value={email} onChange={this.handleChange}  name="email" id="email" placeholder="Inserisci la tua email" />
                  </InputGroup>
                  <InputGroup className='mb-3'>
                    <InputGroupAddon addonType="prepend">
                      <span class="input-group-text bg-chattamee text-white" id="basic-addon1">
                        <FontAwesomeIcon icon={faKey}  />
                      </span>
                    </InputGroupAddon>
                    <Input type="password" value={password} onChange={this.handleChange}  name="password" id="password" placeholder="Inserisci la tua password" />
                  </InputGroup>
                  

                  <Button type='button' onClick={this.handleSubmit} className="btn bg-chattamee text-white btn-block mt-4 mb-4">Entra</Button>
                
                  
                  {error &&
                       <span> <br /><div className={'alert alert-danger'}>{error}</div><br /></span>
                    }
                  
                  <div className='registerBox'>
                    <a href="/registrazione">Registrati</a>
                    <a href="/recuperaPassword" className='pull-right'>Recupera password</a>
                  </div>
                </Form>
                </Col>
              </div>
            </Col>
          
        </Row>
        <section>
        </section>
      </div>
      );
    }
  }

  export default Login;
